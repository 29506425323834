import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="404: Не Найдено" />
        <div className="container container--pad" style={{padding: '6rem 0'}}>
          <h1 className="post__title has-text-centered">
            404<br />
            <span>Не Найдено</span>
          </h1>
          <hr />
          <div className="content column has-text-centered">
            <p>Вы просто выбрали путь, которого не существует.</p>
            <Link to="/" className="button is-link is-light">
              Вернуться на главную
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage
